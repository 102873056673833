<template>
    <div>
        <section class="breadcrumb-area">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-area-content">
                            <h1>Aktyor</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section><!-- breadcrumb area end -->
        <section class="transformers-area">
            <div class="container">
                <div class="transformers-box">
                    <div class="row flexbox-center">
                        <div class="col-lg-5 text-lg-left text-center">
                            <div class="transformers-content">
                                <img :src="$store.state.fileUrl + `/actors/` + actor.photo" alt="actor" />
                            </div>
                        </div>
                        <div class="col-lg-7">
                            <div class="transformers-content">
                                <h2>{{ actor.name }}</h2>
                                <ul>
                                    <li>
                                        <div class="transformers-left">Tug‘ilgan sanasi:</div>
                                        <div class="transformers-right">{{ parseDate(actor.birthday) }}</div>
                                    </li>
                                    <li>
                                        <div class="transformers-left">Fuqaroligi:</div>
                                        <div class="transformers-right">{{ actor.citizenship }}</div>
                                    </li>
                                    <li>
                                        <div class="transformers-left">Bo‘yi:</div>
                                        <div class="transformers-right">{{ actor.heigh }}</div>
                                    </li>
                                    <li>
                                        <div class="transformers-left">Karyera:</div>
                                        <div class="transformers-right">{{ actor.career }}</div>
                                    </li>
                                    <li>
                                        <div class="transformers-left">Filmlar soni:</div>
                                        <div class="transformers-right">{{ actor.totalFilms }}</div>
                                    </li>
                                    <li>
                                        <div class="transformers-left">Birinchi film yili: </div>
                                        <div class="transformers-right">{{ actor.firstFilm }}</div>
                                    </li>
                                    <li>
                                        <div class="transformers-left">Birinchi film nomi:</div>
                                        <div class="transformers-right">{{ actor.firstFilmName }} +</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
	data() {
		return {
            actor: [],
            id: ""
        }
	},
    methods: {
        getData() {
			var _this = this;
			this.$api.get("/apiData/Ref/GetActor?id=" + this.id).then(response => {
				_this.actor = response.result.data[0];
			},
			err => {
				this.$store.getters.errorParse(this, err);
			})
		},
        parseDate(date) {
			var parse = '0';
			if(date && date != 0){
				parse = moment(date).format('DD.MM.YYYY');
			}
			return parse;
		}
    },
	mounted() {
		this.getData();
	},
	created() {
		this.id = this.$route.params.id;
		if (!this.id) {
			this.$router.push({ path: "/" });
			return;
		}
	},
}
</script>